<template>
  <div class="row">
    <a
      v-for="collaborator in collaborators"
      :key="collaborator.id"
      title="Selecteer medewerker"
      class="col-sm-2 user-friends tw-my-2 tw-text-center"
      @click="selectedCollaborator = collaborator.id"
    >
      <img
        :src="getPicture(collaborator)"
        :class="['img-circle tw-inline-block', { 'active': selectedCollaborator === collaborator.id }]"
      />
      <br />
      {{ collaborator.first_name }}
    </a>
    <a
      title="Selecteer medewerker"
      class="col-sm-2 user-friends tw-my-2 tw-text-center"
      @click="selectedCollaborator = currentCollaborator.id"
    >
      <img
        :src="getPicture(currentCollaborator)"
        :class="['img-circle tw-inline-block', { 'active': selectedCollaborator === currentCollaborator.id }]"
      />
      <br />
      Mezelf
    </a>
  </div>
</template>

<script>
/**
 * This component takes a property or a list of collaborators and allows the user to select a number of collaborators.
 * If a property is given the collaborators linked to this property are loaded and shown, otherwise the list of
 * collaborator is shown.
 */
import { mapGetters } from 'vuex'
import { getPropertyCollaborators } from '@/services/properties'
import { getProjectCollaborators } from '@/services/projects'
import ProfileImg from '@/assets/img/profile.png'

export default {
  name: 'CollaboratorSelector',
  props: {
    forProperty: {
      type: Object
    },
    forProject: {
      type: Object
    },
    forCollaborators: {
      type: Array
    }
  },
  data () {
    return {
      selectedCollaborator: null,
      fetchedCollaborators: []
    }
  },
  created () {
    this.setCollaborators()
  },
  computed: {
    ...mapGetters('account', ['collaborator']),
    currentCollaborator () {
      return this.collaborator
    },
    collaborators () {
      if (this.forCollaborators && this.forCollaborators.length) return this.forCollaborators
      return this.fetchedCollaborators
    }
  },
  methods: {
    async setCollaborators () {
      if (this.forProperty || this.forProject) {
        let response = null
        if (this.forProperty) {
          response = await getPropertyCollaborators(this.forProperty.id)
        } else {
          response = await getProjectCollaborators(this.forProject.id)
        }
        this.fetchedCollaborators = response?.data?.results.map(collaborator => {
          // This component expects `id`, not `collaborator_id`, but the entity collaborator's endpoint holds the id of the collaborator in the `collaborator_id` field
          collaborator.id = collaborator.collaborator_id
          return collaborator
        })
      }
    },
    getPicture (collaborator) {
      return collaborator?.picture || ProfileImg
    }
  }
}
</script>

<style scoped>
.active {
  border: 3px solid #1c84c6;
}
</style>
